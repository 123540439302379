import React, { useState, useContext, useMemo, useEffect } from "react"
import { Link, Redirect } from "react-router-dom"
import {
  AppBar,
  Button,
  Toolbar,
  ButtonProps,
  Tooltip,
} from "@material-ui/core"
import StyledMenu from "../StyledMenu"
import { MenuItem } from "@mui/material"
import { get } from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import logo from "../../assets/images/theodc.png"
import { AuthContext } from "../../context/AuthContext"
import FirmType from "../../utils/FirmType"
import * as types from "graphql-types/generated/portal-client-types"
import { MainContext } from "../../context/MainContext"
import HomeIcon from "@mui/icons-material/Home"
import HelpIcon from "@mui/icons-material/Help"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import LogoutIcon from "@mui/icons-material/Logout"
import { Auth } from "aws-amplify"

const POOL_CLIENT_ID: string = (window as any).POOL_CLIENT_ID
const RCG_DATA_COLLECTION_URI: string = (window as any).RCG_DATA_COLLECTION_URI

function isDefined(num?: number | null) {
  return num !== 0 && num !== undefined && num !== null
}

const navStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 500,
    position: "relative",
  },
  logo: {
    pointerEvents: "none",
  },
  bar: {
    marginBottom: "1em",
    backgroundColor: "hsla(211deg,84%,32%,1)",
    "& button": {
      padding: "0.4em 0.8em",
      fontSize: "1rem",
      textTransform: "capitalize",
      fontWeight: "500",
      color: "hsl(0deg,0%,85%)",
      whiteSpace: "nowrap",
    },
    "& button:hover": {
      color: "hsl(0deg,0%,100%)",
      backgroundColor: "hsla(211deg,84%,28%,1)",
    },
  },
}))

function SupportMenu({
  handleOpenNavMenu,
  anchorElNav,
  handleCloseNavMenu,
}: {
  handleOpenNavMenu: (event: React.MouseEvent<HTMLElement>) => void
  anchorElNav: HTMLElement | null
  handleCloseNavMenu: () => void
}) {
  return (
    <>
      <Tooltip title='Support'>
        <Button
          size='large'
          aria-label='support menu'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleOpenNavMenu}
          color='inherit'
        >
          <HelpIcon />
        </Button>
      </Tooltip>
      <StyledMenu
        id='support-menu'
        data-cy='nav-support-menu'
        anchorEl={anchorElNav}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
      >
        <MenuItem>
          <a
            data-cy='nav-faq'
            rel='noopener noreferrer'
            target='_blank'
            href={`${process.env.PUBLIC_URL}/documents/instructions.pdf`}
          >
            View FAQ
          </a>
        </MenuItem>
        <MenuItem>
          <a
            data-cy='nav-contact-support'
            href='mailto:portal@therockcreekgroup.com'
          >
            Email Support
          </a>
        </MenuItem>
      </StyledMenu>
    </>
  )
}

const buildCognitoLocalStorageKeys = (email: string) => {
  const keysWithEmail = [
    "idToken",
    "userData",
    "refreshToken",
    "accessToken",
    "clockDrift",
  ]
  const keys = keysWithEmail.map(
    (key) => `CognitoIdentityServiceProvider.${POOL_CLIENT_ID}.${email}.${key}`,
  )
  keys.push(`CognitoIdentityServiceProvider.${POOL_CLIENT_ID}.LastAuthUser`)
  return keys
}

async function buildRequiredAuthData() {
  const idToken = await (await Auth.currentSession()).getIdToken()
  const email = idToken.payload["email"]
  if (!email) return ""
  const requiredKeys = buildCognitoLocalStorageKeys(email)
  return requiredKeys.reduce((accu, key) => {
    return Object.assign(accu, {
      [key]: window.localStorage.getItem(key),
    })
  }, {})
}

export const DEIProfileLink = ({
  profilePath,
  buttonProps,
  buttonText = "DEI Profile",
}: {
  profilePath: string
  buttonText?: string
  buttonProps?: ButtonProps
}) => {
  //iframe ref
  const DCref = React.useRef<HTMLIFrameElement>(null)

  const messageHandler = async (event: MessageEvent) => {
    console.log("received message", event.data)
    if (event.origin !== RCG_DATA_COLLECTION_URI) return
    if (event.data === "READY_TO_RECIEVE_MESSAGES") {
      console.log(
        "sending auth credentials",
        DCref.current?.contentWindow,
        RCG_DATA_COLLECTION_URI,
      )
      DCref.current?.contentWindow?.postMessage(
        {
          action: "SEND_AUTH_CREDENTIALS",
          cognito: await buildRequiredAuthData(),
        },
        RCG_DATA_COLLECTION_URI,
      )
    }
  }

  // memoize the jwt token
  useEffect(() => {
    window.addEventListener("message", messageHandler)
  }, [window.removeEventListener("message", messageHandler)])

  return (
    <>
      <Link
        data-cy='nav-data-collection-dei'
        to={{
          /* Our SSO is not working, so we'll just let them sign in again as needed
        pathname: `${
          (window as any).RCG_DATA_COLLECTION_URI
        }/portalRedirect?redirect=${profilePath}`,
        */
          pathname: `${(window as any).RCG_DATA_COLLECTION_URI}/login/external`,
        }}
        target='_blank'
      >
        <Button {...buttonProps}>{buttonText}</Button>
      </Link>
      <iframe
        ref={DCref}
        title='dei-profile'
        src={`${(window as any).RCG_DATA_COLLECTION_URI}/${profilePath}`}
        style={{ display: "none" }}
      />
    </>
  )
}

const InvalidUserNavBar = ({
  handleSignOut,
}: {
  handleSignOut: () => void
}) => {
  const styles = navStyles()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  return (
    <AppBar position='static' className={styles.bar}>
      <Toolbar>
        <span className={styles.title}>
          <img src={logo} className={styles.logo} alt='logo' />
        </span>
        <Link data-cy='nav-dashboard' to='/'>
          <Tooltip title='Dashboard'>
            <Button>
              <HomeIcon />
            </Button>
          </Tooltip>
        </Link>
        <SupportMenu
          handleOpenNavMenu={handleOpenNavMenu}
          handleCloseNavMenu={handleCloseNavMenu}
          anchorElNav={anchorElNav}
        />
        <Button data-cy='nav-sign-out' onClick={handleSignOut}>
          <LogoutIcon />
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export function getDEIProfilePath(registeredFirmId?: number | null): string {
  console.log("getDEIProfilePath for: ", registeredFirmId)
  if (isDefined(registeredFirmId)) {
    return `firmFirmDEI/${registeredFirmId}`
  }
  return "firmDEI"
}

interface ValidUserNavProps {
  isCompany: boolean
  handleSignOut: () => void
  primaryFirm: types.Firm | null
  serviceProviderFirms: types.Firm[]
}

const ValidUserNavBar = ({
  isCompany,
  handleSignOut,
  primaryFirm,
  serviceProviderFirms,
}: ValidUserNavProps) => {
  const styles = navStyles()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  if (serviceProviderFirms.length > 0) {
    // multi-firm organizations should add investments + view firms through dashboard (same as admin users)
    return (
      <AppBar position='static' className={styles.bar}>
        <Toolbar>
          <span className={styles.title}>
            <img src={logo} className={styles.logo} alt='logo' />
          </span>
          <Link data-cy='nav-dashboard' to='/'>
            <Tooltip title='Dashboard'>
              <Button>
                <HomeIcon />
              </Button>
            </Tooltip>
          </Link>
          <SupportMenu
            handleOpenNavMenu={handleOpenNavMenu}
            handleCloseNavMenu={handleCloseNavMenu}
            anchorElNav={anchorElNav}
          />

          <Link data-cy='nav-user-profile' to='/profile'>
            <Button>
              <Tooltip title='User Profile'>
                <AccountCircleIcon />
              </Tooltip>
            </Button>
          </Link>

          <DEIProfileLink profilePath={getDEIProfilePath()} />
          <Button data-cy='nav-sign-out' onClick={handleSignOut}>
            <LogoutIcon />
          </Button>
        </Toolbar>
      </AppBar>
    )
  }

  const registeredFirmId = primaryFirm && primaryFirm.id
  return (
    <AppBar position='static' className={styles.bar}>
      <Toolbar>
        <span className={styles.title}>
          <img src={logo} className={styles.logo} alt='logo' />
        </span>
        <Link data-cy='nav-dashboard' to='/'>
          <Tooltip title='Dashboard'>
            <Button>
              <HomeIcon />
            </Button>
          </Tooltip>
        </Link>

        {registeredFirmId && (
          <>
            <Link data-cy='nav-firm-profile' to={`/firm/${registeredFirmId}`}>
              <Button>Firm Profile</Button>
            </Link>
            <DEIProfileLink
              profilePath={getDEIProfilePath(primaryFirm?.rcgFirmId)}
            />
          </>
        )}
        {!isCompany && registeredFirmId && (
          <Link
            data-cy='nav-add-investment'
            to={`/fill-form/${registeredFirmId}`}
          >
            <Button>Add Investment</Button>
          </Link>
        )}
        <SupportMenu
          handleOpenNavMenu={handleOpenNavMenu}
          handleCloseNavMenu={handleCloseNavMenu}
          anchorElNav={anchorElNav}
        />
        <Link data-cy='nav-user-profile' to='/profile'>
          <Button>
            <Tooltip title='User Profile'>
              <AccountCircleIcon />
            </Tooltip>
          </Button>
        </Link>

        <Tooltip title='Sign out'>
          <Button data-cy='nav-sign-out' onClick={handleSignOut}>
            <LogoutIcon />
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}

export const Navbar = () => {
  const [, { signOut }] = useContext(AuthContext)
  const { currentUser, isValidUser } = useContext(MainContext)
  const [logOut, setLogOut] = useState(false)

  const handleSignOut = () => {
    signOut()
    setLogOut(true)
  }

  const primaryFirm: types.Firm | null = get(
    currentUser,
    "organization.primaryFirm",
    null,
  )

  const serviceProviderFirms: types.Firm[] = get(
    currentUser,
    "organization.serviceProviderFirms",
    [],
  )

  const isCompany = useMemo(() => {
    if (!primaryFirm || serviceProviderFirms.length === 0) {
      return false
    }
    return primaryFirm.firmTypeId === FirmType.Company
  }, [serviceProviderFirms, primaryFirm])

  return (
    <>
      {isValidUser ? (
        <ValidUserNavBar
          isCompany={isCompany}
          serviceProviderFirms={serviceProviderFirms}
          primaryFirm={primaryFirm}
          handleSignOut={handleSignOut}
        />
      ) : (
        <InvalidUserNavBar handleSignOut={handleSignOut} />
      )}

      {logOut && <Redirect to='/login' />}
    </>
  )
}

export default Navbar
